import {
  PiCalendarXLight,
  PiCalendarBlankLight,
  PiDownloadSimpleLight,
} from 'react-icons/pi';
import { Link } from 'react-router-dom';

import { CurrentQuiz, StudentTrackWithSchedule } from '../../types';
import { downloadWorkbook } from '../../util';
import { useAppSelector } from '../../stores/AppStore';
import { selectHasOneOfTheRoles } from '../../features/user/userSlice';
import { ROLES } from '../../constants';

interface UpcomingAssignmentProperties {
  assignment: CurrentQuiz;
  track?: StudentTrackWithSchedule;
  canDownloadWorkbook?: boolean;
}

const UpcomingAssignment = ({
  assignment,
  track,
  canDownloadWorkbook = false,
}: UpcomingAssignmentProperties) => {
  const canPreviewQuizzes = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [
      ROLES.mentor,
      ROLES.teacher,
      ROLES.schooladmin,
    ]),
  );
  const handleDownloadWorkbook = () => {
    if (!canDownloadWorkbook || !track || !assignment.workbookUrl) {
      return;
    }
    downloadWorkbook({
      quizId: assignment.id,
      url: assignment.workbookUrl,
    });
  };

  return (
    <div
      data-testid={`upcoming-assignment-${assignment.id}`}
      className={`flex flex-col gap-3 bg-white rounded-xl border border-1-gray p-4 min-h-[156px] ${
        assignment.isReview && 'border border-tttDefault'
      }`}
    >
      <div className="flex gap-3 items-center">
        <p className="font-medium">{assignment.id}</p>
        <div className="flex gap-2 items-center">
          {assignment.isOverdue ? (
            <PiCalendarXLight className="w-4 h-4 text-rose-500" />
          ) : (
            <PiCalendarBlankLight className="w-4 h-4 text-tttDefault" />
          )}
          <p className="text-sm text-gray-700">
            {assignment.isOverdue ? 'Overdue' : `Due ${assignment.dueDate}`}
          </p>
        </div>
      </div>
      <div className="flex gap-3">
        {canPreviewQuizzes ? (
          <Link
            to={
              track
                ? `/quiz/${track.serialNum}/${assignment.id}/practiceNormal`
                : ''
            }
            className="
              px-4 py-1.5 w-max h-max md:my-auto
              rounded-full border border-tttDefault
              text-sm md:text-base
              font-medium text-tttDefault whitespace-nowrap
              transition-all duration-100 ease-in-out
              hover:shadow hover:bg-tttDefault hover:text-white
            "
            title="Preview / try this Quiz"
          >
            {assignment.id}
          </Link>
        ) : (
          <></>
        )}
        <Link
          to={track ? `/quiz/${track.serialNum}/${assignment.id}/practice` : ''}
          className="
          px-4 py-1.5 w-max h-max md:my-auto
          rounded-full border border-tttDefault
          text-sm md:text-base
          font-medium text-tttDefault whitespace-nowrap
          transition-all duration-100 ease-in-out
          hover:shadow hover:bg-tttDefault hover:text-white
        "
          title="Practice alternate version of this Quiz"
        >
          Practice
        </Link>
      </div>
      {canDownloadWorkbook && (
        <div
          onClick={handleDownloadWorkbook}
          className="w-full flex gap-2 text-tttDefault items-center whitespace-nowrap hover:underline cursor-pointer"
        >
          <PiDownloadSimpleLight className="w-5 h-5" />
          Download Workbook
        </div>
      )}
    </div>
  );
};

export default UpcomingAssignment;
