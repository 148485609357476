import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

interface Properties {
  action?: () => void;
  targetPath?: string;
  bgColor: string;
  bgColorHover?: string;
  buttonText?: string;
  children?: ReactNode;
  textColor?: string;
  textSize?: string;
  disabled?: boolean;
  disabledProgress?: string;
  id?: string;
  width?: string;
  title?: string;
}

const Button: FC<Properties> = ({
  action,
  targetPath,
  bgColor,
  bgColorHover,
  buttonText,
  children,
  textColor,
  textSize,
  disabled,
  disabledProgress,
  id,
  width,
  title,
}) => {
  const navigate = useNavigate();

  return (
    <button
      data-testid={id}
      className={`grid items-center m-2 px-3 py-2 md:px-5 ${
        width ?? `min-w-[200px]`
      } uppercase ${
        textSize ?? `text-sm md:text-base`
      } font-medium ${textColor} shadow-lg rounded-full ${
        disabled ? 'bg-slate-400' : bgColor
      } enabled:hover:${
        bgColorHover ?? bgColor
      } enabled:hover:-translate-y-0.5 enabled:hover:cursor-pointer duration-200`}
      onClick={() => {
        if (action) {
          action();
        }
        if (targetPath) {
          navigate(targetPath);
        }
      }}
      disabled={disabled}
      title={title}
    >
      {disabled
        ? disabledProgress || (
            <ClipLoader
              data-testid="button-loader"
              color="#FFFFFF"
              size={24}
              className="justify-self-center"
            />
          )
        : buttonText}
      {!disabled && children}
    </button>
  );
};

export default Button;
