import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  CalculatorIcon,
  ChevronLeftIcon,
  NewspaperIcon,
  PencilIcon,
} from '@heroicons/react/outline';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Line } from 'rc-progress';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';

import { ROLES } from '../constants';
import { store, useAppSelector } from '../stores/AppStore';
import {
  useFinalizeQuizSubmissionMutation,
  useGetIncompleteResultQuery,
  useGetTracksForStudentQuery,
  useStartQuizPracticingQuery,
  useStartQuizSolvingMutation,
} from '../services/apiSlice';
import { handleError } from '../services/ErrorService';
import {
  loadNextProblem,
  loadPreviousProblem,
  selectIsInProgress,
  overviewBeforeSubmit,
  selectIsSubmitted,
  selectCurrentProblem,
  selectIsLastProblem,
  selectIsOverviewBeforeSubmit,
} from '../features/quiz/quizSlice';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';
import useBlockQuizNavigation from '../hooks/useBlockQuizNavigation';
import useUpdatedStudentAssignments from '../hooks/useStudentUpdatedAssignments';
import { ErrorMessage } from '../components/ErrorMessage';
import Finish from '../components/Finish';
import { LoadingBlock } from '../components/Loading';
import Modal from '../components/Modal';
import Problem from '../components/Problem';
import Timer from '../components/Timer';
import Button from '../components/Button';

export default function Quiz() {
  const { practiceOrSolve, quizId, trackId } = useParams();
  const navigate = useNavigate();
  const { state }: { state?: { isIncompleteResult?: boolean } } = useLocation();
  const [isReadyToStartPractice, setIsReadyToStartPractice] = useState(false);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const currentProblemIndex = useAppSelector(
    (state) => state.quiz.selectedProblemIndex,
  );
  const currentProblem = useAppSelector(selectCurrentProblem);
  const user = useAppSelector((state) => state.user.user);
  const quizProgress = useAppSelector((state) => state.quiz.progress);
  const dueAt = useAppSelector((state) => state.quiz.dueAt);
  const isInProgress = useAppSelector(selectIsInProgress);
  const isOverviewBeforeSubmit = useAppSelector(selectIsOverviewBeforeSubmit);
  const isSubmitted = useAppSelector(selectIsSubmitted);
  const isLastProblem = useAppSelector(selectIsLastProblem);
  const isStudentOrThinker = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student, ROLES.thinker]),
  );
  const [finalizeQuizSubmission, { isLoading: isFinalizingQuizSubmission }] =
    useFinalizeQuizSubmissionMutation();
  const { isError: isStartPracticeError, isFetching: isStartPracticeFetching } =
    useStartQuizPracticingQuery(
      {
        quizId: quizId ?? '',
        trackId: trackId ?? '',
        type: practiceOrSolve === 'practiceNormal' ? 'normal' : 'retake',
      },
      {
        skip: practiceOrSolve === 'solve' || !isReadyToStartPractice,
        refetchOnMountOrArgChange: true,
      },
    );
  const [startQuizSolving, { isError, isLoading }] =
    useStartQuizSolvingMutation();
  const { data: track, isFetching: isFetchingTrack } =
    useGetTracksForStudentQuery(
      { studentId: user?._id ?? '' },
      {
        skip: !user?._id || !isStudentOrThinker,
      },
    );
  const { currentAssignments } = useUpdatedStudentAssignments(track);
  const currentQuiz = currentAssignments.find((q) => q.id === quizId);
  // const currentQuiz = useAppSelector((state) => state.quiz.selectedQuiz);
  // FIXME: currentQuiz is not available yet at the overview phase before starting. Only Students can access it via currentAssignments

  const {
    data: incompleteStudentResult,
    isFetching: isFetchingIncompleteStudentResult,
  } = useGetIncompleteResultQuery(undefined, {
    skip: !isStudentOrThinker,
  });

  const handleStartQuizSolving = (quizId: string, trackId: string) => {
    if (state?.isIncompleteResult) {
      startQuizSolving({ quizId, trackId });
      return;
    }
    const shouldStartQuiz = window.confirm(
      'Are you sure you want to start the quiz? Once you start, the timer will begin counting down, and you cannot pause or stop it.',
    );

    if (shouldStartQuiz) {
      if (practiceOrSolve === 'solve') {
        startQuizSolving({ quizId, trackId });
      } else {
        setIsReadyToStartPractice(true);
      }
    }
  };

  useEffect(() => {
    if (
      !quizId ||
      practiceOrSolve !== 'solve' ||
      currentAssignments.length === 0
    ) {
      return;
    }
    const relevantAssignment = currentAssignments.find(
      (assignment) => assignment.id === quizId,
    );
    if (!relevantAssignment?.canStart) {
      navigate('/assignments');
    }
  }, [track, currentAssignments]);

  useEffect(() => {
    if (!trackId) {
      handleError(new Error('Track ID is missing from the URL'), {
        displayedError: 'Track ID is missing from the URL',
        tags: {
          currentQuiz: String(currentQuiz),
          quizIdParam: quizId ?? '',
          currentQuizId: currentQuiz?.id ?? '',
        },
      });
      return;
    }
    if (!quizId) {
      handleError(new Error('Quiz ID is missing from the URL'), {
        displayedError: 'Quiz ID is missing from the URL',
        tags: {
          currentQuiz: String(currentQuiz),
          quizIdParam: quizId ?? '',
          currentQuizId: currentQuiz?.id ?? '',
        },
      });
      return;
    }
  }, []);

  useEffect(() => {
    const checkTimeIsUp = () => {
      if (dayjs(dueAt).isBefore(dayjs()) && isInProgress) {
        setTimeIsUp(true);
        store.dispatch(overviewBeforeSubmit());
      }
    };

    checkTimeIsUp();

    const intervalId = setInterval(checkTimeIsUp, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dueAt, isInProgress]);

  useEffect(() => {
    if (
      !isFetchingIncompleteStudentResult &&
      incompleteStudentResult &&
      dayjs(incompleteStudentResult.dueAt).isBefore(dayjs())
    ) {
      // FIXME: may fail because of isResultUpdatable()
      finalizeQuizSubmission({
        resultId: incompleteStudentResult._id,
        quiz: incompleteStudentResult.quiz,
      });
    }
  }, [isFetchingIncompleteStudentResult]);

  useBlockQuizNavigation({
    practiceOrSolve,
    quizId,
    currentAssignments,
    isLoading,
    isIncompleteResult: state?.isIncompleteResult,
  });

  if (
    isLoading ||
    isStartPracticeFetching ||
    isFetchingTrack ||
    isFinalizingQuizSubmission ||
    isFetchingIncompleteStudentResult
  ) {
    return <LoadingBlock />;
  }

  if (isError || isStartPracticeError) {
    Sentry.captureException(new Error(`Failed to load Quiz`), {
      tags: {
        trackId,
        quizId,
      },
    });
    return <ErrorMessage text={'Failed to load Quiz'} />;
  }

  if (isInProgress && (!currentProblem || currentProblemIndex === undefined)) {
    toast.error('Failed to load problem');
    return <></>;
  }

  const title =
    practiceOrSolve === 'solve'
      ? quizId
      : practiceOrSolve === 'practice'
      ? `${quizId} - Practice`
      : `${quizId} - Preview`;

  return (
    <div
      className={`grid grid-cols-4 gap-6 md:px-6 pb-6 pt-3 rounded-xl transition-all duration-1000 ${
        isSubmitted ? '' : 'bg-gray-100'
      }`}
    >
      <h1 className="col-span-4 text-3xl md:text-4xl font-medium w-full my-4">
        {title}
      </h1>
      <div className="grid col-span-4 px-3 md:px-6">
        {(isInProgress || isOverviewBeforeSubmit) &&
        currentProblemIndex !== undefined &&
        !!currentProblem ? (
          <>
            <div className="flex w-full col-span-4 justify-between pb-10">
              <div className="text-sm">
                Progress {Math.round(quizProgress)}%
                <Line
                  percent={quizProgress}
                  strokeWidth={10}
                  strokeColor={'#0069d9'}
                />
              </div>
              <Timer />
            </div>
            {timeIsUp || isOverviewBeforeSubmit ? (
              <Finish />
            ) : (
              <div className="grid w-full col-span-4 items-center gap-3">
                <div className="grid col-start-1 justify-items-start cursor-pointer h-full">
                  <div
                    data-testid="displayed-problem-number"
                    className="row-start-1 col-start-1 self-start uppercase text-white text-lg font-bold px-2 rounded-full bg-tttDefault"
                  >
                    {currentProblemIndex + 1}
                  </div>
                  {currentProblemIndex > 0 && (
                    <ChevronLeftIcon
                      data-testid={'previous-button'}
                      className="row-start-1 col-start-1 self-center h-8 w-8 text-tttDefault transition ease-in-out duration-300 md:hover:scale-125"
                      onClick={() => store.dispatch(loadPreviousProblem())}
                    />
                  )}
                </div>
                <div className="grid col-start-2 col-span-2 justify-items-center items-center overflow-hidden">
                  <Problem currentProblem={currentProblem} />
                  {quizProgress === 100 && currentProblemIndex !== 9 && (
                    <div className="flex w-full col-span-4 justify-center items-center pt-5">
                      <Button
                        action={() => store.dispatch(overviewBeforeSubmit())}
                        bgColor={'bg-blue-300'}
                        bgColorHover={'bg-blue-400'}
                        buttonText={'Go to finish'}
                      />
                    </div>
                  )}
                </div>
                <div className="grid col-start-4 cursor-pointer justify-items-end">
                  {isLastProblem ? (
                    <div
                      data-testid={'next-button'}
                      className="text-tttDefault font-medium"
                      onClick={() => store.dispatch(overviewBeforeSubmit())}
                    >
                      Finish
                    </div>
                  ) : (
                    <ChevronRightIcon
                      data-testid={'next-button'}
                      className="h-8 w-8 text-tttDefault transition ease-in-out duration-300 md:hover:scale-125"
                      onClick={() => store.dispatch(loadNextProblem())}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        ) : isSubmitted ? (
          <Finish />
        ) : (
          <div className="justify-items-center items-center">
            <Modal
              buttonText="Click to begin"
              buttonAction={() => {
                if (!quizId || !trackId) {
                  handleError(new Error('Track ID or Quiz ID is missing'), {
                    displayedError:
                      'Invalid URL. Please go to your Assignments page and click on a Quiz',
                    tags: {
                      quizId: quizId ?? '',
                      trackId: trackId ?? '',
                    },
                  });
                  return;
                }
                handleStartQuizSolving(quizId, trackId);
              }}
            >
              {practiceOrSolve === 'practice' ? (
                <>
                  <p className="text-center w-2/3">
                    Practice Mode offers carefully selected problems that mirror
                    the original Quiz, helping you strengthen your Math skills
                    and build confidence.
                  </p>
                  {currentQuiz && (
                    <p className="text-center pt-4">
                      You have to solve{' '}
                      <strong>{currentQuiz.problems.length} problems</strong> in
                      this Quiz.
                    </p>
                  )}
                  <p className="font-bold text-center py-4">
                    Once you start this quiz you have{' '}
                    {currentQuiz?.timeLimit
                      ? Math.floor(currentQuiz?.timeLimit / 60)
                      : 30}{' '}
                    minutes to complete it!
                  </p>
                </>
              ) : (
                <></>
              )}
              {practiceOrSolve === 'practiceNormal' ? (
                <>
                  <p>
                    Before Students start, they should have the following items
                    available:
                  </p>
                  <ul>
                    <li className="flex">
                      <PencilIcon className="mr-3 h-5 w-5 text-tttDefault" />
                      Pen or pencil
                    </li>
                    <li className="flex">
                      <NewspaperIcon className="mr-3 h-5 w-5 text-tttDefault" />
                      Scratch paper or graph paper
                    </li>
                    <li className="flex">
                      <CalculatorIcon className="mr-3 h-5 w-5 text-tttDefault" />
                      Calculator
                    </li>
                  </ul>
                  <p className="text-rose-500 font-bold text-center py-4">
                    The assessment has a{' '}
                    {currentQuiz?.timeLimit
                      ? Math.floor(currentQuiz?.timeLimit / 60)
                      : 30}
                    -minute time limit, and the countdown continues even if the
                    browser tab is closed.
                  </p>
                </>
              ) : (
                <></>
              )}
              {practiceOrSolve === 'solve' ? (
                <>
                  <p>
                    Before you start, please have the following items available:
                  </p>
                  <ul>
                    <li className="flex">
                      <PencilIcon className="mr-3 h-5 w-5 text-tttDefault" />
                      Pen or pencil
                    </li>
                    <li className="flex">
                      <NewspaperIcon className="mr-3 h-5 w-5 text-tttDefault" />
                      Scratch paper or graph paper
                    </li>
                    <li className="flex">
                      <CalculatorIcon className="mr-3 h-5 w-5 text-tttDefault" />
                      Calculator
                    </li>
                  </ul>
                  {currentQuiz && (
                    <p className="text-center pt-4">
                      You have to solve{' '}
                      <strong>{currentQuiz.problems.length} problems</strong> in
                      this Quiz.
                    </p>
                  )}
                  <p className="text-rose-500 font-bold text-center py-4">
                    Once you start this quiz you have{' '}
                    {currentQuiz?.timeLimit
                      ? Math.floor(currentQuiz?.timeLimit / 60)
                      : 30}{' '}
                    minutes to complete it!
                  </p>
                  <p className="font-semibold text-center">
                    If you close this tab the time will continue to count down.
                  </p>
                </>
              ) : (
                <></>
              )}
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}
